/*@font-face {*/
/*    font-family: 'Broken';*/
/*    src: local('Broken'), url(./fonts/Broken-Font/Broken-Regular.ttf) format('truetype');*/
/*    !* other formats include: 'woff2', 'truetype, 'opentype',*/
/*                              'embedded-opentype', and 'svg' *!*/
/*}*/
@font-face {
    font-family: 'Mukta Mahee';
    src: local('Mukta_Mahee'), url(./fonts/Mukta_Mahee/MuktaMahee-Light.ttf) format('truetype');
}

.chakra-ui-light .fbomb-tagline{
    fill: black;
}
.chakra-ui-dark  .fbomb-tagline{
    fill: lightgray;
}
